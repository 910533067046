<template>
  <main>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 post-single mt-4"> 
          <PostListItem :postItem="postSingle"  v-if="postSingle"  ></PostListItem>
          <Loader :stat='loader' theme="skeleton-feed" :count="loaderCnt"></Loader>
          <cmtViewer  v-if="postSingle"  :postId="postSingle._id"></cmtViewer>
        </div>
      </div>
      
    </div>
  </main>
</template>
<style scoped>
    .post-single{
        position: relative;
    }
    .cardbox{
      margin-bottom: 0% !important;
    }
    .post-comments-list{
      padding-top: 2rem;
      background: var(--white-clr);
      padding: 2rem 15px;
    }
</style>
<script>
import axios from '@/plugins/axios'

export default {
  name: "Home",
  components: {

  },
  data () {
        return {
            postSingle:'',
            loader:true,
            loaderCnt:1
        }
  },
  created: function () {
     window.scrollTo(0, 0)
  },
  beforeMount() {
    this.getFeedData();
    // var data = JSON.parse(localStorage.getItem("homeFeedList"));
    // if(data){
    //   this.postList =data;
    // }else{
    //   this.getFeedData();
    // }
    
  },
  mounted() {
      
  },
  methods: {
    getFeedData: function () {
        var self = this
        if (self.page == 0 ) return false;
        self.loader=true
        axios.get('/api/feed/s/?postId='+this.$route.params.postid).then(response => {
            self.postSingle =  response.data.data
            self.loader=false
        }).catch(error => {
          self.loader=false
          self.page =0
        })
    },
  },
  watch:{
  },
};
</script>
